<template>
  <v-container fluid>
    <v-col class="legal-text">
      <h1>Cookies Policy</h1>
      <p>Latest update:  January 2022</p>
      <p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site.</p>
      <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.</p>
      <h2>We use the following cookies:</h2>
      <ul>
        <li>
          <strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.
        </li>
        <li>
          <strong>Analytical or performance cookies.</strong> These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.
        </li>
        <li>
          <strong>Functionality cookies.</strong> These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).Targeting cookies. These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.
        </li>
      </ul>
        <hr>
        <h2>Cookies are essential for our site to enables us to:</h2>
        <ul>
          <li>Estimate our audience size and usage pattern.</li>
          <li>Store information about your preferences, and so allow us to customise our site and to provide you with offers that are targeted to your individual interests.</li>
          <li>Speed up your searches.</li>
          <li>Recognise you when you return to our site.</li>
          <li>Allow you to use our site in a way that makes your browsing experience more convenient. If you register with us or complete our online forms, we will use cookies to remember your details during your current visit, and any future visits provided the cookie was not deleted in the interim.</li>
        </ul>
        <hr>
        <p>Please note that the following third parties may also use cookies, over which we have no control. These named third parties may include, for example, advertising networks and providers of external services like web traffic analysis services. These third party cookies are likely to be analytical cookies or performance cookies or targeting cookies.</p>
        <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our website.</p>
    </v-col>
    <v-btn
      color="primary darken-1 white--text back-button"
      elevation="2"
      @click="$router.push('/')"
    >
      Go back
    </v-btn>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.cookies-type {
  margin-left: 2rem;
  &__title {
    font-size: 1.8rem;
    text-transform: capitalize;
  }
}
.cookie-switch {
  margin-left: 2rem;
  &__switch {
    margin-bottom: 1rem;
  }
  &__name {
    font-size: 1.4rem;
    text-transform: capitalize;
  }
  &__description {
    font-size: 1rem;
    margin-left: 1rem;
  }
}
.back-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
}
</style>
